import { useQuery } from '@tanstack/react-query';
import { ProxyPoaFile } from 'types/ProxyPoaFile';
import { api } from 'utils/fetch';

export function usePoaQuery(meetingAttendeeId: number, pin: string, meetingId: number) {
  return useQuery({ queryKey: ['poas'], queryFn: () => getPoas(meetingAttendeeId, pin, meetingId) });
}

async function getPoas(meetingAttendeeId: number, pin: string, meetingId: number): Promise<ProxyPoaFile[]> {
  const response = await api().get(`/external/getPoaList`, {
    params: {
      meetingAttendeeId,
      pin,
      meetingId
    }
  });

  return response.data;
}
