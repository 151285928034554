import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';

function validatePinAndOrgNumber(value: string) {
  if (Personnummer.valid(value) || Organisationsnummer.valid(value)) {
    return true;
  }
}
export function validateOrgNumber(value: string) {
  if (!Personnummer.valid(value) && Organisationsnummer.valid(value)) {
    return true;
  }
}

export default validatePinAndOrgNumber;
