import { Alert, AlertColor, Snackbar, SnackbarCloseReason, SnackbarOrigin } from '@mui/material';
import { createContext, useMemo } from 'react';
import { ReactNode, FC, useState } from 'react';

interface ToastProviderProps {
  children: ReactNode;
}

interface Toast {
  message: string;
  position?: SnackbarOrigin;
  severity?: AlertColor;
  autoHideDuration?: number;
}

interface ToastContextProps {
  createToast: (toast: Toast) => void;
  closeToast: (_event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
}

export const ToastContext = createContext<ToastContextProps>({
  createToast: () => {
    console.warn('createToast has been called without a Toast Context Provider');
  },
  closeToast: () => {
    console.warn('closeToast has been called without a Toast Context Provider');
  }
});

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast>({} as Toast);

  const closeToast = (_event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setToast({} as Toast);
  };

  const createToast = ({
    message,
    position = { vertical: 'top', horizontal: 'center' },
    severity = 'success',
    autoHideDuration = 6000
  }: Toast) => {
    setToast({ message, position, severity, autoHideDuration });
    setOpen(true);
  };

  const memoizedSnackbarValues = useMemo(() => ({ createToast, closeToast }), [toast]);

  return (
    <ToastContext.Provider value={memoizedSnackbarValues}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={toast.autoHideDuration}
        onClose={closeToast}
        message={toast.message}
        anchorOrigin={toast.position}
      >
        <Alert onClose={closeToast} severity={toast.severity} sx={{ width: '500px', maxWidth: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
