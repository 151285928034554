import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useToast } from 'hooks/useToast';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { mapErrorStatusCodeToTranslationKey } from 'utils/error-handling';
import { normalizeKey } from 'utils/translation';

interface Props {
  children: ReactNode;
}

export const ReactQueryProvider: FC<Props> = ({ children }) => {
  const { createToast } = useToast();
  const { t } = useTranslation();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      },
      mutations: {
        onError: error => {
          const err = error as AxiosError;
          const translationKey = mapErrorStatusCodeToTranslationKey(err.status);
          const normalizedTranslationKey = normalizeKey(translationKey);

          createToast({ message: t(normalizedTranslationKey), severity: 'error' });
        }
      }
    }
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
