import axios from 'axios';

export const getConfiguration = async (env?: string) => {
  try {
    const confEndpoint = `${env === 'development' ? 'https://localhost:44388' : ''}/api/Configuration`;
    return await axios.get(confEndpoint);
  } catch (error) {
    console.log(error);
  }
};
