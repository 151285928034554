import ClearRounded from '@mui/icons-material/ClearRounded';
import SaveIcon from '@mui/icons-material/Save';
import UploadOutlined from '@mui/icons-material/UploadOutlined';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, List, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useQueryClient } from '@tanstack/react-query';
import { poaChunkUpload, postUploadCompleteFileNameMeetingId } from 'queries/poaUploadQueries';
import { useDeletePoaMutation } from 'queries/useDeletePoaMutation';
import { usePoaQuery } from 'queries/usePoaQuery';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  meetingId: number;
  meetingAttendeeId: number;
  pin: string;
}

export const FileUploadCard = ({ meetingId, meetingAttendeeId, pin }: Props) => {
  const chunkSize = 1048576 * 1; //1 megabyte in bytes, times amount of megabytes
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUploaded, setFileToBeUploaded] = useState<File>(new File([''], ''));
  const [beginningOfTheChunk, setBeginningOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [fileError, setFileError] = useState('');

  const { t } = useTranslation();
  const { data: poaFiles } = usePoaQuery(meetingAttendeeId, pin, meetingId);
  const { mutate: deletePoaFile } = useDeletePoaMutation();
  const queryClient = useQueryClient();

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    resetChunkProperties();

    if (!e.target || !e.target.files || !e.target.files[0]) {
      return;
    }

    const _file = e.target.files ? e.target.files[0] : new File([''], '');
    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks that has to be uploaded to finish the file
    const _fileID = window.crypto.randomUUID() + '.' + _file.name.split('.').pop();

    if (_file.size > chunkSize * 200) {
      setIsLoading(false);
      setFileError(t('upload.too_large'));
      return;
    }

    if (
      _file.type !== 'application/pdf' &&
      _file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      _file.type !== 'application/msword'
    ) {
      setIsLoading(false);
      setFileError(t('upload.wrong_filetype'));
      return;
    }

    setFileSize(_file.size);
    setChunkCount(_totalCount);
    setFileGuid(_fileID);
    setFileToBeUploaded(_file);
  };

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToBeUploaded, progress]);

  const fileUpload = () => {
    setCounter(counter + 1);

    if (counter <= chunkCount) {
      const chunk = fileToBeUploaded.slice(beginningOfTheChunk, endOfTheChunk);
      const formData: FormData = new FormData();
      formData.append('file', chunk, fileToBeUploaded.name);
      formData.append('ChunkCount', counter.toString());
      formData.append('fileName', fileGuid);
      onChunkUpload(formData);
    }
  };

  const onChunkUpload = async (chunk: FormData) => {
    try {
      await poaChunkUpload(chunk);

      setBeginningOfTheChunk(endOfTheChunk);
      setEndOfTheChunk(endOfTheChunk + chunkSize);
      if (counter == chunkCount) {
        await postUploadCompleteFileNameMeetingId(
          fileGuid,
          meetingAttendeeId,
          meetingId ?? 0,
          pin,
          fileToBeUploaded.name
        );
        setIsLoading(false);
        queryClient.invalidateQueries({ queryKey: ['poas'] });
      } else {
        const percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
      }
    } catch (error) {
      console.error('error', error);
      setIsLoading(false);
    }
  };

  const resetChunkProperties = () => {
    setIsLoading(true);
    setProgress(0);
    setCounter(1);
    setBeginningOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  };

  const removeFile = (fileIndex: number) => {
    deletePoaFile({ fileId: poaFiles ? poaFiles[fileIndex].fileId : '', pin });
  };

  const renderUploadButton = () => {
    if (isLoading) {
      return (
        <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
          {'Uploading'}
        </LoadingButton>
      );
    }

    return (
      <Button variant="outlined" component="label" startIcon={<UploadOutlined />}>
        {'upload'}
        <input type="file" accept=".pdf, .doc, .docx" style={{ display: 'none' }} onChange={handleUpload} />
      </Button>
    );
  };

  const renderErrorMsg = () => {
    if (fileError) {
      return (
        <Paper variant="outlined" sx={{ p: 1, bgcolor: red[600], border: 'none' }}>
          <Typography variant="body2" color="white">
            {fileError}
          </Typography>
        </Paper>
      );
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="body2">{t('registration_page.uploadPoa')}</Typography>

      {renderErrorMsg()}

      {renderUploadButton()}

      <List dense disablePadding>
        {poaFiles?.map((file, index) => {
          return (
            <ListItem key={file.fileName + index}>
              <ListItemText primary={`${index + 1} - ${file.fileName}`} />
              <IconButton onClick={() => removeFile(index)}>
                <ClearRounded />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};
