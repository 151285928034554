import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

export default function Spinner() {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress />
    </Container>
  );
}
