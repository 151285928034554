import { AuthProvider } from 'providers/auth';
import { MuiThemeProvider } from 'providers/mui';
import { ReactQueryProvider } from 'providers/react-query';
import { FC, ReactNode } from 'react';
import { ToastProvider } from './toast';
import { ConfigurationProvider } from './ConfigurationProvider';

interface Props {
  children: ReactNode;
}

export const Providers: FC<Props> = ({ children }) => {
  return (
    <MuiThemeProvider>
      <ConfigurationProvider>
        <ToastProvider>
          <ReactQueryProvider>
            <AuthProvider>{children}</AuthProvider>
          </ReactQueryProvider>
        </ToastProvider>
      </ConfigurationProvider>
    </MuiThemeProvider>
  );
};
