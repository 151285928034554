import axios from 'axios';
import { CONFIGURATION } from 'providers/ConfigurationProvider';

export const api = (isAuth: boolean = false) => {
  const apiInstance = axios.create({
    baseURL: `${CONFIGURATION.baseUrl}${isAuth ? 'auth' : 'api'}`
  });

  return apiInstance;
};
