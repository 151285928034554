import { useQuery } from '@tanstack/react-query';
import { Voting } from 'types/agenda';
import { api } from 'utils/fetch';

export function useVoteQuery(meetingId?: number, attendeeId?: number) {
  return useQuery({
    queryKey: ['vote', attendeeId],
    queryFn: () => getVoting(meetingId, attendeeId),
    enabled: !!meetingId && attendeeId !== undefined
  });
}

async function getVoting(meetingId?: number, attendeeId?: number): Promise<Voting> {
  const response = await api().get(`/external/meetings/${meetingId}/voting/${attendeeId}`);

  return response.data;
}
