import { Button, Modal, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ParseKeys, t } from 'i18next';

interface Props {
  isOpen: boolean;
  confirmationQuestion: ParseKeys;
  handleConfirmation: (isConfirmed: boolean) => void;
}

export const ConfirmationModal: FC<Props> = ({ isOpen, confirmationQuestion, handleConfirmation }) => {
  return (
    <Modal open={isOpen} onClose={() => handleConfirmation(false)}>
      <Stack
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Paper variant="outlined" sx={{ display: 'flex', flexDirection: 'column', p: 4, gap: 2 }}>
          <Typography>{t(confirmationQuestion)}</Typography>
          <Stack gap={2} direction={'row'}>
            <Button onClick={() => handleConfirmation(false)} variant="contained" fullWidth>
              {t('no')}
            </Button>
            <Button onClick={() => handleConfirmation(true)} variant="outlined" fullWidth>
              {t('yes')}
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </Modal>
  );
};
