import { useQuery } from '@tanstack/react-query';
import { BankIdUser } from 'providers/auth';
import { api } from 'utils/fetch';

export function useLoginQuery() {
  return useQuery({ queryKey: ['loginStatus'], queryFn: () => getLoginStatus() });
}

async function getLoginStatus(): Promise<BankIdUser | undefined> {
  const response = await api().get(`/external/loginstatus`);

  return response.data;
}
