const validStatusCodes = [500, 400, 401, 403, 404, 409] as const;

type StatusCode = '409' | '500' | '404' | '400' | '401' | '403';

export function mapErrorStatusCodeToTranslationKey(statusCode: number | undefined) {
  if (statusCode && validStatusCodes.includes(statusCode as (typeof validStatusCodes)[number])) {
    const code = statusCode.toString() as StatusCode;
    return `status_code.${code}`;
  }

  return `status_code.500`;
}
