import { ToastContext } from 'providers/toast';
import { useContext } from 'react';

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context == null) {
    throw new Error('useToast must be used within ToastProvider');
  }

  return context;
};
