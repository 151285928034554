import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IAttendee, mapAttendeeToAttendeeDto } from 'types/attendee';
import { api } from 'utils/fetch';

export function useRegistrationMutation(onSuccess: (email: string | undefined) => void) {
  return useMutation({
    mutationKey: ['registration'],
    mutationFn: (attendee: IAttendee) => postMeetingRegistration(attendee),
    onSuccess: (_: AxiosResponse, attendee: IAttendee) => {
      onSuccess(attendee.email);
    }
  });
}

async function postMeetingRegistration(attendee: IAttendee) {
  return await api().post(`/external/meetingattendee`, mapAttendeeToAttendeeDto(attendee));
}
