import ClearRounded from '@mui/icons-material/ClearRounded';
import {
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddUnderlyingShareholders } from 'components/AddUnderlyingShareholders';
import { RepresentsDropdown } from 'components/RepresentsDropdown';
import { FC, useState, Dispatch, SetStateAction } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FileUploadCard } from 'components/FileUploadCard';
import Personnummer from 'personnummer';
import { useRegistrationMutation } from 'queries/useRegistrationMutation';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Advisor, AttendanceType, IAttendee, NotificationMethod, OwnerType } from 'types/attendee';
import { IMeeting } from 'types/meeting';
import formatPINAndOrgNr from 'utils/pin';
import { z } from 'zod';
import Organisationsnummer from 'organisationsnummer';
import { useAuth } from 'hooks/useAuth';
import { useWithdrawNotificationMutation } from 'queries/useWithdrawNotificationMutation';
import { ConfirmationModal } from './ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { green } from '@mui/material/colors';
import { getLocaleDateString } from 'utils/date';
import { useLocale } from 'hooks/useLocale';

const PersonSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1)
});

const ShareholderSchema = z.object({
  personOrOrganizationNumber: z
    .string()
    .min(10)
    .max(13)
    .refine(data => Personnummer.valid(data) || Organisationsnummer.valid(data)),
  firstName: z.string().trim(),
  lastName: z.string().trim().min(1)
});

const FormSchema = ShareholderSchema.extend({
  email: z.union( [
    z.literal( '' ),
    z.string().trim().email(),
  ] ),
  mobilePhone: z.string(),
  ownerType: z.nativeEnum(OwnerType),
  advisors: z.array(PersonSchema),
  underlyingShareholders: z.array(ShareholderSchema)
});

export type RegisterForm = z.infer<typeof FormSchema>;

interface RegisterFormProps {
  meeting: IMeeting;
  pin: string | undefined;
  setIsShowingSuccessMsg: Dispatch<SetStateAction<boolean>>;
}

export const RegisterForm: FC<RegisterFormProps> = ({ meeting, pin, setIsShowingSuccessMsg }) => {
  const params = useParams();
  const meetingId = Number(params.meetingId);
  const withdrawNotificationMutation = useWithdrawNotificationMutation();
  const [isWithdrawConfirmationOpen, setIsWithdrawConfirmationOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const [advisorFields, setAdvisorFields] = useState<Advisor>({ firstName: '', lastName: '' });
  const [advisorFieldsErrors, setAdvisorFieldsErrors] = useState<Advisor>({ firstName: '', lastName: '' });
  const [successMsg, setSuccessMsg] = useState('');
  const { locale } = useLocale();
  setIsShowingSuccessMsg(successMsg != '')

  const { handleSubmit, control, reset, watch } = useForm<RegisterForm>({
    defaultValues: {
      personOrOrganizationNumber: user?.pin?.toString() ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: meeting.email ?? '',
      advisors: meeting.advisors ?? [],
      underlyingShareholders:
        meeting.underlyingShareholders?.map(s => {
          return {
            personOrOrganizationNumber: s.personOrOrganizationNumber,
            firstName: s.firstName,
            lastName: s.lastName
          } as Advisor;
        }) ?? [],
      mobilePhone: meeting.phoneNumber ?? '',
      ownerType: meeting.ownerType === OwnerType.NotSet ? OwnerType.Owner : meeting.ownerType
    },
    resolver: zodResolver(FormSchema)
  });

  const onSuccessSubmitForm = (email: string | undefined) => {
    if (email) {
      setSuccessMsg(t('registration_page.registering_done_with_email', { email: email }));
    } else {
      setSuccessMsg(t('registration_page.registering_done_no_email'));
    }
  };

  const registrationMutation = useRegistrationMutation(onSuccessSubmitForm);

  const isProxy =
    watch('ownerType') === OwnerType.Proxy_IsNotRepresentingOwnShares || watch('ownerType') === OwnerType.Proxy;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'advisors'
  });

  const {
    fields: underlyingFields,
    append: appendUnderlying,
    remove: removeUnderlying
  } = useFieldArray({
    control,
    name: 'underlyingShareholders'
  });

  const onSubmit: SubmitHandler<RegisterForm> = data => {
    setSuccessMsg('');
    const result = FormSchema.safeParse(data);

    if (!result.success) {
      console.error(result.error.format());
      return;
    }

    const { firstName, lastName, ownerType, underlyingShareholders, advisors, email, mobilePhone } = data;

    const attendeeId = meeting?.attendeeId ?? 0;
    const underlyingPinUnformated = underlyingShareholders.map(y => {
      return {
        firstName: y.firstName,
        lastName: y.lastName,
        personOrOrganizationNumber: y.personOrOrganizationNumber.replace('-', '')
      };
    });

    const attendee: IAttendee = {
      id: attendeeId,
      meetingId,
      personOrOrganizationNumber: data.personOrOrganizationNumber.replace('-', ''),
      firstName,
      lastName,
      ownerType: (ownerType as string) === OwnerType.Proxy_IsNotRepresentingOwnShares ? OwnerType.Proxy : ownerType,
      isRepresentingOwnShares: (ownerType as string) !== OwnerType.Proxy_IsNotRepresentingOwnShares,
      notificationMethod: NotificationMethod.Digital,
      attendanceType: AttendanceType.Physical,
      isVerified: !!user?.pin,
      advisors,
      email: email,
      mobilePhone: mobilePhone,
      underlyingShareholders: ownerType !== OwnerType.Owner ? underlyingPinUnformated : [],
      proxyPoAFiles: []
    };
    registrationMutation.mutate(attendee);

    reset();
  };

  const handleWithdraw = (shouldWithdraw: boolean) => {
    if (shouldWithdraw) {
      withdrawNotificationMutation.mutate({
        meetingId: meetingId,
        attendeeId: meeting.attendeeId,
        pin: user?.pin ?? ''
      });
    }
    setIsWithdrawConfirmationOpen(false);
  };

  const handleAddAdvisor = () => {
    if (isAdvisorFieldsErrors()) {
      return;
    }

    append(advisorFields);
    setAdvisorFields({ firstName: '', lastName: '' });
  };

  const isAdvisorFieldsErrors = () => {
    if (advisorFields.firstName.length > 0 && advisorFields.lastName.length > 0) {
      setAdvisorFieldsErrors({ firstName: '', lastName: '' });

      return false;
    }

    if (advisorFields.firstName.length == 0) {
      setAdvisorFieldsErrors(prev => ({ ...prev, firstName: 'required' }));
    }

    if (advisorFields.lastName.length == 0) {
      setAdvisorFieldsErrors(prev => ({ ...prev, lastName: 'required' }));
    }

    return true;
  };

  function renderRepresentsSection() {
    if (!user?.pin) {
      return null;
    }

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">{t('registration_page.representHeading')}</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <RepresentsDropdown control={control} />
        </Grid>
      </>
    );
  }

  function renderAddUnderlying() {
    if (isProxy) {
      return (
        <>
          <Grid xs={12}>
            <Typography variant="h6">{t('registration_page.representOwnersHeading')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <Typography variant="body2">{t('registration_page.supplyRepresentedInfo')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <AddUnderlyingShareholders
              fields={underlyingFields}
              append={appendUnderlying}
              remove={removeUnderlying}
              proxyId={meeting?.attendeeId ?? 0}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <FileUploadCard meetingId={meetingId} meetingAttendeeId={meeting?.attendeeId ?? 0} pin={pin ?? ''} />
          </Grid>
        </>
      );
    }
  }

  if (successMsg) {
    return (
      <Paper variant="outlined" sx={{ p: 1, bgcolor: green[200], border: 'none' }}>
        <Typography variant="h6">{successMsg}</Typography>
        <Typography variant="subtitle2">
          {t('registration_page.registeration_date')}: {getLocaleDateString(new Date(), locale)}
        </Typography>
      </Paper>
    );
  }

  const renderFormButtons = () => {
    if (meeting.lastRegistrationDate < new Date()) {
      return null;
    }

    if (meeting.registrationDate && user?.pin) {
      return (
        <Grid xs={12} sm={12} md={12} container>
          <Grid xs={6} sm={6} md={6}>
            <Button type="submit" variant="contained" fullWidth>
              {t('registration_page.update_notification_button')}
            </Button>
          </Grid>
          <Grid xs={6} sm={6} md={6}>
            <Button type="button" onClick={() => setIsWithdrawConfirmationOpen(true)} variant="outlined" fullWidth>
              {t('registration_page.withdraw_notification_button')}
            </Button>
          </Grid>
          <ConfirmationModal
            isOpen={isWithdrawConfirmationOpen}
            handleConfirmation={handleWithdraw}
            confirmationQuestion={'registration_page.form.withdraw_confirmation'}
          />
        </Grid>
      );
    }

    return (
      <Grid xs={12} sm={12} md={12}>
        <Button type="submit" variant="contained" fullWidth>
          {t('registration_page.send_notification_button')}
        </Button>
      </Grid>
    );
  };

  if (registrationMutation.isPending) {
    return <CircularProgress />;
  }
  if (registrationMutation.isError) {
    return <p>{registrationMutation.error.message}</p>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Grid container spacing={2} xs={12} sm={12} md={6}>
          <Grid xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="personOrOrganizationNumber"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.pin')}
                    name="bday"
                    autoComplete="bday"
                    value={value ? formatPINAndOrgNr(value.replace('-', '')) : ''}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    disabled={!!user?.pin}
                    required
                    helperText={
                      error?.message || (value && !Personnummer.valid(value))
                        ? t('registration_page.error.invalid_pin')
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="firstName"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.first_name')}
                    name="fname"
                    autoComplete="fname"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    disabled={!!user?.firstName}
                    required
                    helperText={error?.message ? t('registration_page.error.mandatory_field') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="lastName"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.last_name')}
                    name="lname"
                    autoComplete="lname"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    disabled={!!user?.lastName}
                    required
                    helperText={error?.message ? t('registration_page.error.mandatory_field') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="email"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.email')}
                    name="email"
                    autoComplete="email"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message ? t('registration_page.error.invalid_email') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="mobilePhone"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.mobile_number')}
                    name="mobilePhone"
                    autoComplete="mobilePhone"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message ? error.message : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">{t('registration_page.advisors')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} padding={0}>
            <List dense disablePadding>
              {fields.map((advisor, index) => {
                return (
                  <ListItem key={window.crypto.randomUUID()}>
                    <ListItemText
                      primary={` ${advisor.firstName} ${advisor.lastName}`}
                      secondary={`${t('registration_page.advisor')} ${index + 1}`}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <ClearRounded />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          {fields.length < 2 && (
            <Grid xs={12} sm={6} md={4.5}>
              <TextField
                placeholder={t('registration_page.form.first_name')}
                name="fname"
                autoComplete="fname"
                size="small"
                fullWidth
                error={!!advisorFieldsErrors.firstName}
                helperText={advisorFieldsErrors.firstName ? t('registration_page.error.mandatory_field') : null}
                value={advisorFields.firstName}
                onChange={event => setAdvisorFields(prev => ({ ...prev, firstName: event.target.value }))}
              />
            </Grid>
          )}
          {fields.length < 2 && (
            <Grid xs={12} sm={6} md={4.5}>
              <TextField
                placeholder={t('registration_page.form.last_name')}
                name="lname"
                autoComplete="lname"
                size="small"
                fullWidth
                error={!!advisorFieldsErrors.lastName}
                helperText={advisorFieldsErrors.lastName ? t('registration_page.error.mandatory_field') : null}
                value={advisorFields.lastName}
                onChange={event => setAdvisorFields(prev => ({ ...prev, lastName: event.target.value }))}
              />
            </Grid>
          )}
          {fields.length < 2 && (
            <Grid xs={12} sm={12} md={3}>
              <Button variant="outlined" fullWidth onClick={handleAddAdvisor}>
                {t('add_button')}
              </Button>
            </Grid>
          )}
          {renderRepresentsSection()}
          {renderAddUnderlying()}
          {renderFormButtons()}
        </Grid>
      </Stack>
    </form>
  );
};
