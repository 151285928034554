import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import { api } from 'utils/fetch';
import { useNavigate } from 'react-router-dom';
import { mapMeetingShortInfoDtoToInternal } from 'types/meeting';

export function useWithdrawNotificationMutation() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['withdrawNotification'],
    mutationFn: ({ meetingId, attendeeId, pin }: { meetingId: number; attendeeId: number; pin: string }) =>
      deleteNotification(meetingId, attendeeId, pin),
    onSuccess: async data => {
      await queryClient.setQueryData(['meetings', user?.pin ?? 0], mapMeetingShortInfoDtoToInternal(data.data));
      navigate('/');
    }
  });
}

async function deleteNotification(meetingId: number, attendeeId: number, pin: string) {
  return await api().delete(`/external/DeleteNotification/${meetingId}/${attendeeId}/${pin}`);
}
