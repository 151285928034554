import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'utils/fetch';

export function useDeletePoaMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['deletePoa'],
    mutationFn: ({ fileId, pin }: { fileId: string; pin: string }) => deletePoa({ fileId, pin }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['poas'] });
    }
  });
}

async function deletePoa({ fileId, pin }: { fileId: string; pin: string }) {
  return await api().delete(`/external/DeletePoa/${fileId}/${pin}`);
}
