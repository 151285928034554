import Alert from '@mui/material/Alert';
import Spinner from 'components/Spinner';
import { getConfiguration } from 'queries/ConfigurationQuery';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ISettings } from 'types/ISettings';

export type IConfigurationContext = ISettings;

export const ConfigurationContext = createContext<IConfigurationContext | null>(null);

interface Props {
  children: ReactNode;
}

export const CONFIGURATION: IConfigurationContext = { baseUrl: '', envName: '' };

export const ConfigurationProvider = ({ children }: Props) => {
  const [configuration, setConfiguration] = useState<IConfigurationContext | null>(null);
  const [localApiError, setLocalApiError] = useState<boolean>(false);

  useEffect(() => {
    const fetchSettings = async () => {
      if (configuration === null) {
        try {
          const resp = await getConfiguration(import.meta.env.MODE);

          if (resp) {
            setConfiguration(resp.data);

            CONFIGURATION.baseUrl = resp.data.baseUrl;
            CONFIGURATION.envName = resp.data.aspnetcore_environment;
          }
        } catch (error) {
          setLocalApiError(true);
        }
      }
    };
    fetchSettings();
  }, []);

  return (
    <ConfigurationContext.Provider value={configuration}>
      {configuration === null && !localApiError && <Spinner />}
      {configuration !== null && children}
      {localApiError && <Alert severity="error">Missing configuration settings</Alert>}
    </ConfigurationContext.Provider>
  );
};

export function useConfiguration() {
  const ctx = useContext(ConfigurationContext);
  if (ctx === null) {
    throw new Error('useConfiguration must be used within ConfigurationProvider');
  }
  return ctx;
}
