import { useQuery } from '@tanstack/react-query';
import { api } from 'utils/fetch';

export function usePersonCanbeUnderlying(meetingId: number, orgOrPersonalNumber: string | null, proxyId: number) {
  return useQuery({
    queryKey: ['personCanBeUnderlying', orgOrPersonalNumber, meetingId, proxyId],
    queryFn: () => getPersonCanBeUnderlying(meetingId, proxyId, orgOrPersonalNumber),
    enabled: !!orgOrPersonalNumber && !!meetingId && orgOrPersonalNumber.length > 9
  });
}

interface ErrorRes {
  error: string;
  canBeAdded: boolean;
}

async function getPersonCanBeUnderlying(
  meetingId: number,
  proxyId: number,
  orgOrPersonalNumber: string | null
): Promise<ErrorRes> {
  const response = await api().get(`/external/${meetingId}/personCanBeUnderlying`, {
    params: {
      orgOrPersonalNumber,
      proxyId
    }
  });
  if (response?.data?.error) {
    const error = response.data as ErrorRes;
    return {
      canBeAdded: error.canBeAdded,
      error: error.error
    };
  }

  return {
    canBeAdded: true,
    error: ''
  };
}
